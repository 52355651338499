





























































































































































































































































































































































































































































import {
  Component, Vue, Prop, Watch, Model,
} from 'vue-property-decorator';
import { getComponent, getConfigEnv, getView } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import metaDataModule from '@/store/modules/metaDataModule';
import { IDENTITY_POOL_ID, SCHEDULE_BUCKET_NAME, SCHEDULE_BUCKET_REGION } from '@/lib/constants';
import AWS from 'aws-sdk';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})

//

export default class FormFieldDetails extends Vue {
   @Prop({ type: Object, required: false }) field?: any;

   @Prop({ type: Array, required: false }) allFields?: any;

   @Prop({ type: Number, required: false, default: 0 }) formId?: any;

   @Prop({ type: Boolean, default: false, required: false }) isFeedback?: any;

   windowWidth = window.innerWidth;

   showDetails = true;

   dropdownLocation: any = []

   openTab = 1

   localFieldValue: any = '';

   showImagePreview = false;

   showImageEdit = true;

   activeTableID: any = null;

   visibilitySelectedField = [];

   localField: any = {}

   showRequiredErrorMsg = false;

   didSelectDropdownOption() {
     if (this.field.isMultiselect !== true) {
       this.dropdownLocation = [];
     }
     this.emitValUpdatedEvent();
   }

   emitValUpdatedEvent() {
     if (this.formId) {
       this.$nextTick(() => {
         this.updateTextValue(this.localFieldValue);
       });
     } else {
       this.$nextTick(() => {
         this.field.localVal = this.localFieldValue;
         this.$emit('refresh-form-fields');
       });
     }
   }

   hideDropdown() {
     this.dropdownLocation = [];
   }

   addValTShortList(option) {
     if (this.field.isMultiselect) {
       const currentIndex = this.localFieldValue.findIndex((val) => val === option);
       if (currentIndex > -1) {
         this.localFieldValue.splice(currentIndex, 1);
       } else {
         this.localFieldValue.push(option);
       }
     } else {
       this.localFieldValue = [option];
     }
     this.emitValUpdatedEvent();
   }

   isListOptionSelected(option) {
     return this.localFieldValue.includes(option);
   }

   didSelectDropdown(e) {
     console.log(e);
     const rect = e.srcElement.parentElement.getBoundingClientRect();
     this.dropdownLocation = [rect.x, rect.y + 80];
   }

   created() {
     // eslint-disable-next-line no-use-before-define
     if (this.field.localVal) {
       //  console.log((this.field.localVal));
       this.localFieldValue = (this.field.localVal);
       this.showImageEdit = false;
     } else if (this.field.category === 'Dropdown') {
       this.localFieldValue = [];
     }

     // eslint-disable-next-line no-use-before-define
     if (this.field.category === 'Attachments') {
       // eslint-disable-next-line no-use-before-define
       testField = this;
       // eslint-disable-next-line no-use-before-define
       setupDragAndDropSection();
     }
   }

   validateField() {
     if (this.field.category !== 'Subtitle' && this.field.category !== 'Heading' && this.visibility) {
       if ((!this.localFieldValue || this.localFieldValue.length === 0) && this.field.required) {
         this.showRequiredErrorMsg = true;
         return true;
       }
     }
     return false;
   }

   changeInputValue(value) {
     if (this.formId) {
       this.$nextTick(() => {
         this.updateTextValue(this.localFieldValue);
       });
     } else {
       this.$nextTick(() => {
         this.field.localVal = this.localFieldValue;
         this.$emit('refresh-form-fields');
       });
     }
   }

   updateTextValue(value) {
     this.$nextTick(() => {
       if (this.formId) {
         this.$emit('update-text-field-value', { fieldId: this.field.id, formId: this.formId, value });
       }
     });

     //  this.field.localVal = value;
   }

   removeInputValue(value) {
     if (this.field.isMultiselect) {
       this.localFieldValue = this.localFieldValue.filter((v) => v !== value);
     } else {
       this.localFieldValue = '';
     }
     //  this.localFieldValue = value;
     this.field.localVal = this.localFieldValue;
     this.$emit('refresh-form-fields');
   }

   get sourceOptions() {
     const { datalists } = metaDataModule;

     //  datalists = datalists.push({ title: 'Custom', id: 'CUSTOM' })

     return datalists.map((list) => ({
       text: list.title,
       value: list.id,
     }));
     //  return result;
   }

   get getStylesForDoneButton() {
     if (!this.field.title.length) {
       return 'background: #b7b7b7; cursor: no-drop';
     }
     return 'background: #5be5c1; width: 175px;';
   }

   async selectSource() {
     await this.dropdownOptions(this.field);
   }

   async selectSourceType(item) {
     if (this.field.sourceType !== 'Custom') {
       this.field.isCustomSource = false;
     } else {
       this.field.isCustomSource = true;
     }
   }

   get dateFormatting() {
     if (this.field.type === 'Time' && this.field.format === '12 Hours') {
       return 'G:i:S K';
     } if (this.field.type === 'Time' && this.field.format === '24 Hours') {
       return 'H:i';
     } if (this.field.type === 'Date & Time' && this.field.format === '24 Hours') {
       return 'm/d/Y H:i';
     } if (this.field.type === 'Date & Time' && this.field.format === '12 Hours') {
       return 'm/d/Y G:i:S K';
     }

     return 'm/d/Y';
   }

   optionForField(title) {
     const field = this.allFields.find((f) => f.title === title);

     if (field && field.options) {
       return field.options;
     }
     return [];
   }

   updatedKey = 0;

   get visibility() {
     let isVisibile = true;
     if (this.field && this.field && this.field.visibilityRules && this.field.visibilityRules.length) {
       const visibilityFirstRule = this.field.visibilityRules.find((rule) => rule.condition === null);
       if (visibilityFirstRule && visibilityFirstRule.fieldName && visibilityFirstRule.matchValue) {
         isVisibile = false;
         if (this.allFields.find((field) => field.title === visibilityFirstRule.fieldName && field.localVal && field.localVal.includes(visibilityFirstRule.matchValue))) {
           isVisibile = true;
         }
       } else {
         isVisibile = true;
         return isVisibile;
       }

       const visibilityOrRules = this.field.visibilityRules.filter((rule) => rule.condition === 'OR');
       if (visibilityOrRules && visibilityOrRules.length) {
         visibilityOrRules.array.forEach((rule) => {
           if (this.allFields.find((field) => field.title === rule.fieldName && field.localVal && field.localVal.includes(rule.matchValue))) {
             isVisibile = true;
           }
         });
       }
       const visibilityAndRules = this.field.visibilityRules.filter((rule) => rule.condition === 'AND');
       if (visibilityAndRules && visibilityAndRules.length) {
         const conditionCheck: any = [];
         visibilityOrRules.array.forEach((rule) => {
           if (this.allFields.find((field) => field.title === rule.fieldName && field.localVal && field.localVal.includes(rule.matchValue))) {
             conditionCheck.push(true);
           } else {
             conditionCheck.push(false);
           }
         });
         if (conditionCheck.includes(false)) {
           isVisibile = false;
         } else {
           isVisibile = true;
         }
       }
     }

     if (isVisibile === false) {
       this.localFieldValue = '';
       this.field.localVal = this.localFieldValue;
       //  this.$emit('refresh-form-fields');
     }

     return isVisibile;
   }

   async dropdownOptions(id) {
     if (this.field.isCustomSource) {
       this.field.options = [];
     }
     if (this.field.source && this.field.source.value !== 'CUSTOM') {
       const datalistPayload = await metaDataModule.getDatalist(this.field.source.value);
       if (datalistPayload && datalistPayload.length) {
         this.field.options = datalistPayload[0].ListItems.map((item) => item.Name);
       }
     }
   }

   @Watch('showImageEdit')
   onShowImageEditUpdate(value) {
     if (value) {
       if (this.field.category === 'Attachments') {
         // eslint-disable-next-line no-use-before-define
         setupDragAndDropSection();
       }
     }
   }

   async previewFiles(evt) {
     // console.log(evt);
     const event: any = evt;
     const fileUpload = event.target.files[0];
     this.processScheduledItemFile(fileUpload);
     //  setupDragAndDropSection();
   }

   processScheduledItemFile(file) {
     const extension = file.name.split('.').pop();

     const operator = getConfigEnv('OPERATOR_LOWERCASED');
     this.$emit('set-saving-details-banner-id', `Uploading "${file.name}"`);

     AWS.config.update({
       region: SCHEDULE_BUCKET_REGION,
       credentials: new AWS.CognitoIdentityCredentials({
         IdentityPoolId: IDENTITY_POOL_ID,
       }),
     });

     const s3 = new AWS.S3({
       apiVersion: '2006-03-01',
       params: {
         Bucket: SCHEDULE_BUCKET_NAME,
       },
     });

     const key = `${operator}/${accountModule.user.email.toLowerCase()}/${file.name}`;
     if (extension == 'PNG' || extension == 'png' || extension == 'jpeg' || extension == 'jpg') {
       s3.upload({
         Key: key,
         Bucket: SCHEDULE_BUCKET_NAME,
         Body: file,
         ACL: 'public-read',
       },
       (err, data) => {
         if (err) {
           console.log(err, 'there was an error uploading your file');
         } else {
           const s3_url = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
           this.localFieldValue = { url: s3_url, name: file.name, size: file.size };

           this.showImageEdit = false;
           this.field.localVal = this.localFieldValue;

           console.log(`URL Generated: ${s3_url}`);
         }
       });
     } else if (extension == 'pdf') {
       s3.upload({
         Key: key,
         Bucket: SCHEDULE_BUCKET_NAME,
         Body: file,
         ContentDisposition: 'inline',
         ACL: 'public-read',
         ContentType: 'application/pdf',
       },
       (err, data) => {
         if (err) {
           console.log(err, 'there was an error uploading your file');
         } else {
           const s3_url = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
           this.localFieldValue = { url: s3_url, name: file.name, size: file.size };

           this.showImageEdit = false;
           this.field.localVal = this.localFieldValue;

           console.log(`URL Generated: ${s3_url}`);
         }
       });
     }
   }
}

let testField: any = null;

function setupNewLayerDropArea() {
  if (stored_zip_file != null) {
        document.getElementById('afterShapefileUploadID') !.style.display = 'grid';
        document.getElementById('defaultShapefileUploadAreaID') !.style.display = 'none';
        // @ts-ignore
        document.getElementById('fileDragName') !.value = stored_zip_file.name;
        // @ts-ignore
        document.getElementById('fileDragSize') !.value = stored_zip_file.size;
        // @ts-ignore
        document.getElementById('fileDragType') !.value = stored_zip_file.type;

        testField.processScheduledItemFile(stored_zip_file);

    // document.getElementById('add_shapefile_input') !.value = stored_zip_file.name;
  } else {
        document.getElementById('afterShapefileUploadID') !.style.display = 'none';
        document.getElementById('defaultShapefileUploadAreaID') !.style.display = 'block';
        // @ts-ignore
        document.getElementById('fileDragName') !.value = '';
        // @ts-ignore
        document.getElementById('fileDragSize') !.value = '';
        // @ts-ignore
        document.getElementById('fileDragType') !.value = '';
  }
}

let stored_zip_file;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setupDragAndDropSection() {
  sleep(500).then(() => {
    const inputElement = document.getElementById('add_shapefile_input') !;
    if(inputElement) {
      inputElement.onchange = function (event) {
      // @ts-ignore
      const fileList = inputElement.files;
      stored_zip_file = fileList[0];
      setupNewLayerDropArea();
      // TODO do something with fileList.
    };
    }
    const holder = document.getElementById('holder');
    if(holder){
       // @ts-ignore
    holder.ondragover = function () {
      // @ts-ignore
      this.className = 'hover';
      return false;
    };
    // @ts-ignore
    holder.ondragexit = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondragend = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondragleave = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondrop = function (e) {
      // @ts-ignore
      this.className = '';
      e.preventDefault();
      // @ts-ignore
      stored_zip_file = e.dataTransfer.files[0];
      setupNewLayerDropArea();
    };
    }
  });
}

